@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.styles_checkField__3bDXq {
  display: flex;
}
.styles_checkField__3bDXq .styles_checkBoxWrapper__1KwYm {
  margin-right: 9px;
}

.styles_checkBox__3wpdE {
  cursor: pointer;
  outline: none !important;
  padding: 0;
  background: transparent;
  border: 0;
}

p {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.9);
  font-weight: 500;
}
@media (max-width: 767.98px) {
  p {
    font-size: 12px;
  }
}
p a {
  text-decoration: underline;
  color: rgba(43, 57, 84, 0.9);
}

.styles_tooltipWrapper__1jq7r {
  position: absolute;
  bottom: 100%;
  z-index: 999999999;
  padding-bottom: 5px;
}
@media (min-width: 767.98px) {
  .styles_tooltipWrapper__1jq7r {
    left: -87px;
  }
}
@media (min-width: 575.98px) and (max-width: 767.98px) {
  .styles_tooltipWrapper__1jq7r {
    left: -50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media (max-width: 575.98px) {
  .styles_tooltipWrapper__1jq7r {
    right: 0;
  }
  .styles_tooltipWrapper__1jq7r p {
    max-width: calc(100vw - 100px);
  }
}
.styles_tooltipWrapper__1jq7r .styles_tooltip__20gaP {
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 20px;
  margin: 0;
  width: 270px;
  background: #fff;
  color: #2b3954;
}

.styles_title__tsBgx {
  position: relative;
}
.styles_title__tsBgx h3 {
  color: #2b3954;
  display: flex;
}
@media (max-width: 767.98px) {
  .styles_title__tsBgx h3 {
    font-size: 16px;
  }
}
.styles_title__tsBgx h3 i {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.4);
}
.styles_title__tsBgx .styles_infoIcon__22MI3 {
  position: relative;
  margin-left: 5px;
}
.styles_title__tsBgx .styles_infoIcon__22MI3 .styles_tooltipWrapper__1jq7r {
  display: none;
}
.styles_title__tsBgx .styles_infoIcon__22MI3:hover .styles_tooltipWrapper__1jq7r {
  display: block;
}

.styles_phraseContent__3ypzs .styles_viewKey__1h18a {
  font-size: 14px;
  font-weight: 500;
  color: #416ed5;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 50px;
}
.styles_phraseContent__3ypzs .styles_viewKey__1h18a span {
  cursor: pointer;
}

.styles_downloadBtnWrapper__1UaXA {
  text-align: center;
  margin-top: 30px;
}
.styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP {
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #2b3954;
  background: transparent;
  outline: 0 !important;
  border: 2px solid black;
  padding: 13px 30px;
}
@media (max-width: 767.98px) {
  .styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP {
    padding: 13px 24px;
  }
}
@media (max-width: 575.98px) {
  .styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP {
    font-size: 14px;
    padding: 13px;
  }
}
@media (max-width: 359.98px) {
  .styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP {
    font-size: 12px;
  }
}
.styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP.styles_disable__20uYn {
  color: #fff;
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
}

.styles_warning__u5sO3 {
  font-size: 16px;
  color: #d0021b;
  font-weight: 500;
  line-height: 21px;
}

.styles_privateKeyModal___Z2qH h2 {
  color: #2b3954;
}
.styles_privateKeyModal___Z2qH .styles_privateKey__2zwNi {
  word-break: break-all;
  color: #2b3954;
}
.styles_privateKeyModal___Z2qH p {
  margin: 40px 0px;
}
.styles_privateKeyModal___Z2qH .styles_downloadBtnWrapper__1UaXA {
  margin-top: 70px;
}
.styles_privateKeyModal___Z2qH .styles_downloadBtnWrapper__1UaXA .styles_downloadBtn__5CwOP {
  width: 200px;
}
.styles_root__27c_A {
  max-width: 770px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
}

.styles_close__1ra69 {
  position: absolute;
  top: 7%;
  right: 20px;
  line-height: 1;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  font-weight: 600;
  width: 30px;
}

.styles_card__1xmSV {
  border-radius: 2px;
}

.styles_head__3Hfvl {
  border-radius: 2px;
  height: 90px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #222222;
}

.styles_body__2SDNT {
  padding: 60px 40px 40px;
}
@media (max-width: 575.98px) {
  .styles_body__2SDNT {
    padding: 30px 25px;
  }
}
.styles_root__28a1Q {
  max-width: 620px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
}

.styles_close__1GZbv {
  position: absolute;
  top: 50%;
  right: 20px;
  line-height: 1;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  font-weight: 600;
  width: 30px;
}

.styles_card__2As87 {
  border-radius: 2px;
}

.styles_head__2nz5z {
  position: relative;
  border-radius: 2px;
  height: 90px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #3072f8;
}
@media (max-width: 767.98px) {
  .styles_head__2nz5z {
    height: 83px;
  }
}

.styles_body__2BZsB {
  padding: 30px 40px 40px;
}
@media (max-width: 575.98px) {
  .styles_body__2BZsB {
    padding: 30px 25px;
  }
}
.styles_contentWrapper__1213L {
  padding-left: 80px;
}
@media (max-width: 767.98px) {
  .styles_contentWrapper__1213L {
    padding-left: 0;
  }
}

.styles_editBtn__Cytqy {
  height: auto !important;
  min-width: auto !important;
  border: 0 !important;
  padding: 0;
  font-size: 14px !important;
}

.styles_fields__3V4By {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .styles_fields__3V4By {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}
.styles_fields__3V4By .styles_label__2nBpb {
  width: 160px;
}
@media (max-width: 767.98px) {
  .styles_fields__3V4By .styles_label__2nBpb {
    font-size: 16px;
  }
}
.styles_fields__3V4By .styles_value__XCeTT {
  padding-left: 44px;
}
@media (max-width: 767.98px) {
  .styles_fields__3V4By .styles_value__XCeTT {
    padding-left: 0;
    font-size: 18px;
  }
}
.styles_fields__3V4By .styles_value__XCeTT button {
  margin-left: 20px;
}

.styles_validatorAddrValue__1eQVb {
  padding-left: 44px;
  font-size: 10px;
}
@media (max-width: 767.98px) {
  .styles_validatorAddrValue__1eQVb {
    padding-left: 0;
  }
}
.styles_validatorAddrValue__1eQVb button {
  margin-left: 20px;
}

.styles_btnWrapper__scVMY {
  margin-top: 30px;
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
  .styles_btnWrapper__scVMY {
    margin-top: 20px;
  }
}
@media (min-width: 767.98px) {
  .styles_btnWrapper__scVMY button {
    max-width: 156px;
  }
}
.styles_value__5ov14 {
  color: #2b3954;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.styles_addTitle__2HYN3 {
  color: #222222;
}

.styles_addCard__hjy9C {
  min-height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_addCardBtn__3nOKr button {
  height: 58px;
  width: calc(100% - 30px);
  padding-right: 3rem;
  padding-left: 3rem;
}
@media (max-width: 767.98px) {
  .styles_addCardBtn__3nOKr button {
    padding-right: 2rem;
    padding-left: 2rem;
    width: 100%;
  }
}
@media (max-width: 359.98px) {
  .styles_addCardBtn__3nOKr button {
    font-size: 14px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.styles_addBtn__39CJd {
  width: 80px;
  background: transparent;
  border: 0;
}

.styles_addCardWrapper__34GcF {
  max-width: 370px;
  margin: 0 auto;
}
.styles_input__WBQ21 {
  margin: 35px 0;
  position: relative;
}
.styles_input__WBQ21 .styles_label__2avcS {
  font-size: 16px;
  font-weight: 600;
  color: #2b3954;
}
@media (max-width: 767.98px) {
  .styles_input__WBQ21 .styles_label__2avcS {
    font-size: 12px;
    font-weight: bold;
  }
}
.styles_input__WBQ21 .styles_inputWrapper__17XEN {
  position: relative;
}
.styles_input__WBQ21 .styles_inputBox__2xH5N {
  background: #f8f9fa;
  border-color: #f8f9fa;
  position: relative;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  color: #2b3954;
}
.styles_input__WBQ21 .styles_inputBox__2xH5N.styles_textLight__mbkC_ {
  font-weight: 400;
}
.styles_input__WBQ21 .styles_errorInput__263Nj {
  border-color: #d0021b;
}
.styles_input__WBQ21 .styles_errorText__qFwrP {
  color: #d0021b;
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  line-height: normal;
}
.styles_input__WBQ21 .styles_errorText__qFwrP.styles_warning__3uuGL {
  color: #416ed5;
}
.styles_input__WBQ21 .styles_errorText__qFwrP.styles_accessWallet__1Cj1x {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
  color: #d0021b;
}
.styles_input__WBQ21 .styles_errorText__qFwrP i {
  margin-top: 2px;
}
.styles_input__WBQ21 .styles_eyeIcon__TULB7 {
  background: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  outline: 0 !important;
  cursor: pointer;
}
.styles_input__WBQ21 .styles_eyeIcon__TULB7 img {
  width: 18px;
}
.styles_input__WBQ21 .styles_eyeIcon__TULB7 i {
  color: rgba(43, 57, 84, 0.7);
}
.styles_inputContainer__148GF {
  margin: 40px 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .styles_inputContainer__148GF {
    margin: 30px 0;
  }
}
.styles_inputContainer__148GF .styles_labelWrapper__2lQic {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.styles_inputContainer__148GF .styles_label__1Fy1k {
  font-size: 18px;
  font-weight: 600;
  color: #2b3954;
  margin-right: 18px;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .styles_inputContainer__148GF .styles_label__1Fy1k {
    font-size: 16px;
  }
}
.styles_inputContainer__148GF .styles_labelSubText__3zzDL {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.styles_inputContainer__148GF .styles_entireBalance__19Sfe {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  text-align: right;
  cursor: pointer;
  color: #222222;
}
@media (max-width: 767.98px) {
  .styles_inputContainer__148GF .styles_entireBalance__19Sfe {
    font-size: 11px;
  }
}
.styles_inputContainer__148GF .styles_inputWrapper__3l9Lz {
  position: relative;
}
.styles_inputContainer__148GF .styles_input__q19z4 {
  background: #f8f9fa;
  border-color: #f8f9fa;
  position: relative;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  color: #2b3954;
  height: 40px;
  box-shadow: none;
}
@media (max-width: 767.98px) {
  .styles_inputContainer__148GF .styles_input__q19z4 {
    font-size: 14px;
  }
}
.styles_inputContainer__148GF .styles_input__q19z4::-webkit-input-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_inputContainer__148GF .styles_input__q19z4:-moz-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_inputContainer__148GF .styles_input__q19z4::-moz-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_inputContainer__148GF .styles_input__q19z4:-ms-input-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_lg__1Odw4 {
  font-size: 24px;
  font-weight: 600;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_lg__1Odw4::-webkit-input-placeholder {
  font-weight: 600;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_lg__1Odw4:-moz-placeholder {
  font-weight: 600;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_lg__1Odw4::-moz-placeholder {
  font-weight: 600;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_lg__1Odw4:-ms-input-placeholder {
  font-weight: 600;
}
.styles_inputContainer__148GF .styles_input__q19z4.styles_error__2rjjW {
  border-color: rgba(236, 50, 151, 0.9);
}
.styles_inputContainer__148GF .styles_errorText__3sYAq {
  color: #ec3297;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.styles_inputContainer__148GF .styles_warning__2UjTd {
  font-size: 12px;
  color: #416ed5;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  line-height: normal;
}
.styles_inputContainer__148GF .styles_warning__2UjTd i {
  margin-top: 2px;
}
.styles_card__3eguX {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
@media (max-width: 767.98px) {
  .styles_card__3eguX {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (min-width: 767.98px) {
  .styles_inputsWrapper__WDWfk {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.styles_title__NSO1f {
  font-weight: 800;
}

.styles_btnWrapper__1fzdY {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.styles_btn__27CwA {
  width: 200px;
}
.styles_btn__27CwA.styles_send__2y5_l {
  margin-bottom: 10px;
  font-size: 21px;
}
.styles_btn__27CwA.styles_clear__Vszd9 {
  font-size: 14px;
  font-weight: 500;
}

@media (min-width: 767.98px) {
  .styles_passwordModal__2xQuq {
    max-width: 870px;
  }
}
.styles_passwordModal__2xQuq > div {
  border-radius: 2px;
  border: 0;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
}
@media (min-width: 767.98px) {
  .styles_passwordModal__2xQuq > div {
    min-height: 301px;
  }
}
.styles_passwordModal__2xQuq .styles_body__2vIJb {
  padding: 20px 40px 40px;
}
.styles_passwordModal__2xQuq .styles_sendBtn__2FWqS {
  margin-top: 35px;
  width: 200px;
}
.styles_passwordModal__2xQuq label {
  font-size: 18px !important;
}
.styles_passwordModal__2xQuq p {
  margin-top: 20px !important;
}
.styles_passwordModal__2xQuq ::-webkit-input-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__2xQuq ::-moz-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__2xQuq ::-ms-input-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__2xQuq ::placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.styles_iconGap__3C5Ph {
  margin-bottom: 40px;
}

.styles_copyBtn__19K_d {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

.styles_transCard__1JURS {
  text-align: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.styles_hash__35-6r {
  display: flex;
  flex-wrap: wrap;
}
.styles_hash__35-6r a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 18px);
  max-width: calc(100% - 18px);
}
.styles_hash__35-6r .styles_copyBtn__19K_d {
  flex: 0 0 18px;
  max-width: 18px;
}
.styles_hash__35-6r .styles_copyBtn__19K_d svg {
  width: 18px;
  height: 20px;
  color: rgba(43, 57, 84, 0.5);
}
.styles_placeholder__327AN {
  height: 100px;
}
@media (max-width: 768px) {
  .styles_placeholder__327AN {
    height: 64px;
  }
}

.styles_header__v_FN0 {
  display: flex;
  align-items: center;
  height: 121px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: #131313;
  padding: 20px 55px;
  z-index: 999;
}
@media (max-width: 767.98px) {
  .styles_header__v_FN0 {
    height: 73px;
    padding: 20px 25px;
  }
}

.styles_logo__2479P img {
  height: 41px;
}
@media (max-width: 767.98px) {
  .styles_logo__2479P img {
    width: 138px;
  }
}
.styles_footer__3M8n0 {
  margin-top: 30px;
}
.styles_footer__3M8n0 ul {
  padding: 0 0 0 15px;
}
.styles_footer__3M8n0 li {
  padding: 5px 0;
}
.styles_footer__3M8n0 a {
  text-decoration: none;
}
.styles_footer__3M8n0 h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #00b1ff;
  padding-left: 15px;
}
.styles_footer__3M8n0 .styles_footerContent__2DtSY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #131313;
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  .styles_footer__3M8n0 .styles_footerContent__2DtSY {
    flex-direction: column;
  }
}
.styles_footer__3M8n0 h6 {
  color: rgba(19, 19, 19, 0.8);
}
@media (max-width: 767.98px) {
  .styles_footer__3M8n0 h6 {
    order: 2;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .styles_footer__3M8n0 .styles_socialIcon__3NLBi {
    order: 1;
  }
}
.styles_footer__3M8n0 .styles_socialIcon__3NLBi i {
  margin: 15px 35px;
  font-size: 25px;
}
@media (max-width: 359.98px) {
  .styles_footer__3M8n0 .styles_socialIcon__3NLBi i {
    margin: 15px;
  }
}
.styles_layout__3_e7e {
  display: flex;
  flex-direction: column;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transition: -webkit-filter 0.25s;
  transition: -webkit-filter 0.25s;
  transition: filter 0.25s;
  transition: filter 0.25s, -webkit-filter 0.25s;
  will-change: filter;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.styles_layout__3_e7e::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #131313;
  width: 100%;
  height: 512px;
}
@media (max-width: 767.98px) {
  .styles_layout__3_e7e::before {
    height: 370px;
  }
}
.styles_layout__3_e7e main {
  flex-grow: 1;
  padding: 9px 0;
  position: relative;
  z-index: 1;
  min-height: 400px;
}
.styles_layout__3_e7e.styles_blurred__U2D7u {
  -webkit-filter: blur(10px);
          filter: blur(10px);
  touch-action: none;
  pointer-events: none;
}
@media (min-width: 767.98px) {
  .styles_modal__l_yu5 {
    max-width: 680px;
  }
}
.styles_modal__l_yu5 > div {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
}
@media (min-width: 767.98px) {
  .styles_modal__l_yu5 > div {
    min-height: 470px;
  }
}

.styles_header__2576I {
  display: flex;
  align-items: center;
  min-height: 60px;
  background: #131313;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.styles_header__2576I h5 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}
.styles_header__2576I button {
  color: #fff !important;
  opacity: 1;
  padding: 6px 20px !important;
  font-size: 38px;
}
@media (min-width: 767.98px) {
  .styles_modal__173mq {
    max-width: 570px;
  }
}
.styles_modal__173mq > div {
  border-radius: 2px;
  border: 0;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
}
@media (min-width: 767.98px) {
  .styles_modal__173mq > div {
    min-height: 375px;
  }
}
.styles_modal__173mq .styles_body__1UkVs {
  padding: 40px 50px;
}
@media (max-width: 767.98px) {
  .styles_modal__173mq .styles_body__1UkVs {
    padding: 35px;
  }
}
@media (min-width: 767.98px) {
  .styles_modal__2KbMU {
    max-width: 470px;
  }
}
.styles_modal__2KbMU > div {
  border-radius: 2px;
  border: 0;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
}
@media (min-width: 767.98px) {
  .styles_modal__2KbMU > div {
    min-height: 300px;
  }
}
.styles_modal__2KbMU .styles_body__qCcji {
  padding: 40px 50px;
}
@media (min-width: 767.98px) and (max-width: 1260.98px) {
  .styles_createWalletWrap__oh7Ay {
    padding: 50px 0;
  }
}
.styles_createWalletWrap__oh7Ay .styles_checkField__3lkri {
  display: flex;
}
.styles_createWalletWrap__oh7Ay .styles_checkField__3lkri .styles_checkBoxWrapper__3NZQx {
  margin-right: 9px;
}
.styles_createWalletWrap__oh7Ay .styles_checkBox__xJ6yy {
  cursor: pointer;
  outline: none !important;
  padding: 0;
  background: transparent;
  border: 0;
}
.styles_createWalletWrap__oh7Ay p {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.9);
  font-weight: 500;
}
.styles_createWalletWrap__oh7Ay p a {
  text-decoration: underline;
  color: rgba(43, 57, 84, 0.9);
}
.styles_createWalletWrap__oh7Ay .styles_tooltipWrapper__3eyzr {
  position: absolute;
  bottom: 100%;
  z-index: 999999999;
  left: 0;
  padding-bottom: 5px;
}
.styles_createWalletWrap__oh7Ay .styles_tooltipWrapper__3eyzr .styles_tooltip__2JCEK {
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 20px;
  margin: 0;
  width: 270px;
  background: #fff;
  color: #2b3954;
}

.styles_title__THzO6 {
  position: relative;
}
.styles_title__THzO6 h3 {
  color: #2b3954;
  display: flex;
}
.styles_title__THzO6 h3 i {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.4);
}
.styles_title__THzO6 .styles_infoIcon__3tF8W {
  position: relative;
}
.styles_title__THzO6 .styles_infoIcon__3tF8W .styles_tooltipWrapper__3eyzr {
  display: none;
}
.styles_title__THzO6 .styles_infoIcon__3tF8W:hover .styles_tooltipWrapper__3eyzr {
  display: block;
}

.styles_phraseContent__22USg .styles_viewKey__3sVET {
  font-size: 14px;
  font-weight: 500;
  color: #416ed5;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 50px;
}
.styles_phraseContent__22USg .styles_viewKey__3sVET span {
  cursor: pointer;
}

.styles_downloadBtnWrapper__3RabK {
  text-align: center;
  margin-top: 30px;
}
.styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx {
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #2b3954;
  background: transparent;
  outline: 0 !important;
  border: 2px solid #131313;
  padding: 13px 30px;
}
.styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx.styles_disable__2Y2N9 {
  color: #fff;
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
}
@media (max-width: 767.98px) {
  .styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx {
    padding: 13px 24px;
  }
}
@media (max-width: 575.98px) {
  .styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx {
    font-size: 14px;
    padding: 13px;
  }
}
@media (max-width: 359.98px) {
  .styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx {
    font-size: 12px;
  }
}

.styles_warning__1mykR {
  font-size: 16px;
  color: #d0021b;
  font-weight: 500;
  line-height: 21px;
}

.styles_privateKeyModal__35yHE h2 {
  color: #2b3954;
}
.styles_privateKeyModal__35yHE .styles_privateKey__3_JE1 {
  word-break: break-all;
  color: #2b3954;
}
.styles_privateKeyModal__35yHE p {
  margin: 40px 0px;
}
.styles_privateKeyModal__35yHE .styles_downloadBtnWrapper__3RabK {
  margin-top: 70px;
}
.styles_privateKeyModal__35yHE .styles_downloadBtnWrapper__3RabK .styles_downloadBtn__ASefx {
  width: 200px;
}
.styles_phrase__W6-41 {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
  color: #2b3954;
  position: relative;
}
.styles_phrase__W6-41 .styles_cross__pP8Lo {
  color: #2b3954;
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  top: 2px;
  right: 21px;
  outline: 0 !important;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .styles_phrase__W6-41 .styles_cross__pP8Lo {
    right: 0;
  }
}

.styles_mnemonicRow__2G_JA {
  margin-left: -21.5px;
  margin-right: -21.5px;
}

.styles_mnemonicCol__27d_b {
  padding-left: 21.5px;
  padding-right: 21.5px;
}

.styles_mnemonicBtn__2hI6w {
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #2b3954;
  border: solid 1px #2b3954;
}
.styles_mnemonicBtn__2hI6w.styles_isDisable__dyQoG {
  opacity: 0.5;
  cursor: not-allowed;
}
@media (min-width: 767.98px) and (max-width: 1260.98px) {
  .styles_createWalletWrap__avvt8 {
    padding: 50px 0;
  }
}
.styles_createWalletWrap__avvt8 .styles_checkField__1xsJb {
  display: flex;
}
.styles_createWalletWrap__avvt8 .styles_checkField__1xsJb .styles_checkBoxWrapper__3eEoU {
  margin-right: 9px;
}
.styles_createWalletWrap__avvt8 .styles_checkBox__2SEmb {
  cursor: pointer;
  outline: none !important;
  padding: 0;
  background: transparent;
  border: 0;
}
.styles_createWalletWrap__avvt8 p {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.9);
  font-weight: 500;
}
.styles_createWalletWrap__avvt8 p a {
  text-decoration: underline;
  color: rgba(43, 57, 84, 0.9);
}
.styles_createWalletWrap__avvt8 .styles_tooltipWrapper__3ea0b {
  position: absolute;
  bottom: 100%;
  z-index: 999999999;
  left: 0;
  padding-bottom: 5px;
}
.styles_createWalletWrap__avvt8 .styles_tooltipWrapper__3ea0b .styles_tooltip__xerm3 {
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 20px;
  margin: 0;
  width: 270px;
  background: #fff;
  color: #2b3954;
}

.styles_title__2ci8r {
  position: relative;
}
.styles_title__2ci8r h3 {
  color: #2b3954;
}
.styles_title__2ci8r h3 i {
  font-size: 14px;
  color: rgba(43, 57, 84, 0.4);
}
.styles_title__2ci8r .styles_infoIcon__2B5yy {
  position: relative;
}
.styles_title__2ci8r .styles_infoIcon__2B5yy .styles_tooltipWrapper__3ea0b {
  display: none;
}
.styles_title__2ci8r .styles_infoIcon__2B5yy:hover .styles_tooltipWrapper__3ea0b {
  display: block;
}

.styles_phraseContent__2Jhp_ .styles_viewKey__2pcy4 {
  font-size: 14px;
  font-weight: 500;
  color: #416ed5;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 50px;
}
.styles_phraseContent__2Jhp_ .styles_viewKey__2pcy4 span {
  cursor: pointer;
}

.styles_downloadBtnWrapper__2y1yq {
  text-align: center;
  margin-top: 30px;
}
.styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq {
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #2b3954;
  background: transparent;
  outline: 0 !important;
  border: 2px solid black;
  padding: 13px 30px;
}
.styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq.styles_disable__1wJjn {
  color: #fff;
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
}
@media (max-width: 767.98px) {
  .styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq {
    padding: 13px 24px;
  }
}
@media (max-width: 575.98px) {
  .styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq {
    font-size: 14px;
    padding: 13px;
  }
}
@media (max-width: 359.98px) {
  .styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq {
    font-size: 12px;
  }
}

.styles_warning__3-ASq {
  font-size: 16px;
  color: #d0021b;
  font-weight: 500;
  line-height: 21px;
}

.styles_privateKeyModal__3hZW1 h2 {
  color: #2b3954;
}
.styles_privateKeyModal__3hZW1 .styles_privateKey__51fOv {
  word-break: break-all;
  color: #2b3954;
}
.styles_privateKeyModal__3hZW1 p {
  margin: 40px 0px;
}
.styles_privateKeyModal__3hZW1 .styles_downloadBtnWrapper__2y1yq {
  margin-top: 70px;
}
.styles_privateKeyModal__3hZW1 .styles_downloadBtnWrapper__2y1yq .styles_downloadBtn__2P7zq {
  width: 200px;
}

.styles_incorrect_mnemonic__1KlYD {
  font-size: 12px;
  font-weight: 500;
  color: #d0021b;
}

.styles_verifyBtnWrapper__3ajaH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.styles_verifyBtnWrapper__3ajaH button {
  width: 220px;
  height: 50px;
  border-radius: 2px;
  min-width: 70px;
}
.styles_verifyBtnWrapper__3ajaH button.styles_btn-topaz__xhxGb {
  color: #000;
}
.styles_verifyBtnWrapper__3ajaH button.styles_btn-secondary__3OVAf:disabled {
  background: #d5d5d5 !important;
  border-color: #d5d5d5 !important;
}
.styles_verification__2lcwR p {
  font-size: 16px;
  color: #2b3954;
}
.styles_mainWrapper__3mOrD {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 488px;
}

.styles_center__bvW6E {
  text-align: center;
}

.styles_container__1uYCF {
  background: #171717;
  padding-top: 60px;
}

.styles_warn__18NdN {
  color: red;
}

.styles_buttons__1Zs9g {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  flex-direction: column;
}
.styles_buttons__1Zs9g > button {
  margin: 10px 0;
}

.styles_content__2tZUz {
  max-width: 800px;
  margin: auto;
  background: rgba(34, 34, 34, 0.5);
}

.styles_inputs__ppDUY {
  padding: 20px;
  text-align: center;
}
.styles_inputs__ppDUY textarea {
  text-align: center;
}

.styles_dropzone__3Vbgd {
  min-height: 25vh;
  flex: 1 0 25vh;
  border-radius: 2px;
  border: 4px dashed #999999;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.styles_dropzone__3Vbgd h2 {
  text-transform: uppercase;
  opacity: 0.3;
}
.styles_dropzone__3Vbgd input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.styles_or__1JPFJ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 30px 0;
  font-size: 20px;
}
.styles_or__1JPFJ::before, .styles_or__1JPFJ::after {
  content: " ";
  height: 2px;
  flex: 1 1;
  background: white;
}
.styles_or__1JPFJ span {
  margin: 0 10px;
}

.styles_error__2XvOt {
  color: red;
  margin-top: 10px;
}

.styles_optionsWrapper__18BwA {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 51px;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 200px;
  max-width: 200px;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 .styles_option__2f-qS {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 17px 12px 20px;
  text-align: center;
  cursor: pointer;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 .styles_option__2f-qS h4 {
  margin-bottom: 2px;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 .styles_option__2f-qS:not(.styles_active__3k7Iy) {
  background-color: #f8f9fa;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 .styles_option__2f-qS.styles_active__3k7Iy {
  box-shadow: 0 0 0 3px #222222;
}
.styles_optionsWrapper__18BwA .styles_optionCol__1ev08 .styles_option__2f-qS svg {
  margin-bottom: 17px;
}

.styles_inputWrapper__s7A9t {
  position: relative;
}
.styles_inputWrapper__s7A9t .styles_errorText__3rN9l {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #d0021b;
}

.styles_inputLabel__3p9lF {
  margin-bottom: 10px;
}

.styles_input__rKMPw {
  font-size: 16px;
  font-weight: 500;
  border: 0;
  color: #2b3954;
  border-radius: 2px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #f8f9fa !important;
  width: 100%;
  padding: 15px 16px;
}
.styles_input__rKMPw.styles_isError__7t6zy {
  border: solid 1px #d0021b;
}
.styles_input__rKMPw.styles_textarea__M08w9 {
  width: 100% !important;
  min-height: 130px;
  height: 130px;
  max-height: 200px;
  margin-bottom: 80px;
}

.styles_btn__2pJUb {
  width: 240px;
}

.styles_fileUploadBtnWrapper__E9-FW {
  margin-bottom: 40px;
  position: relative;
}
.styles_fileUploadBtnWrapper__E9-FW .styles_fileUploadBtn__2_gOm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.styles_fileUploadBtnWrapper__E9-FW .styles_fileUploadBtn__2_gOm img {
  height: 20px;
  margin-right: 16px;
}
.styles_fileUploadBtnWrapper__E9-FW .styles_info__2u2_- {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #222222;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
}
.styles_panel__3z_6I {
  background: #00b1ff;
  height: 48px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.styles_title__ctJQQ {
  flex: 1 1;
  padding: 12px 30px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles_right__1bZ1x {
  flex: 0 1;
}
.styles_wrap___LAJP {
  padding: 30px;
}
.styles_wrap___LAJP h2 {
  padding: 0 0 30px 0;
  text-transform: uppercase;
  text-align: center;
}

.styles_form__3XqEP {
  padding-top: 10px;
}

.styles_item__2R5bR {
  padding: 10px 0;
}

.styles_button__36wRB {
  text-align: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.styles_button__36wRB > button {
  margin: 5px 0;
}

.styles_error__2Xh34 {
  color: red;
}
.styles_button__3NHFF {
  height: 48px;
  position: relative;
  min-width: 65px;
  color: #1c1c1c;
  border-radius: 0;
  background-color: #fff;
  outline: none;
  box-shadow: none;
}
.styles_button__3NHFF:hover, .styles_button__3NHFF:active {
  background-color: #fff;
  color: #1c1c1c;
  outline: none;
  box-shadow: none;
}
.styles_input__15qZv {
  position: relative;
}
.styles_input__15qZv i {
  position: absolute;
  bottom: 9px;
  left: 7px;
  font-size: 18px;
}
.styles_input__15qZv img {
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  bottom: 9px;
  left: 7px;
}
.styles_input__15qZv input {
  outline: none !important;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  padding: 5px;
  -webkit-transition: border 0.25s;
  transition: border 0.25s;
  font-size: 16px;
}
.styles_input__15qZv input:focus {
  border-bottom: 1px solid #00b1ff;
}
.styles_input__15qZv.styles_usual__3mNNR input {
  border: 1px solid #474747;
  background: #333333 !important;
  border-radius: 2px;
}
.styles_input__15qZv.styles_seamless__2Cc9d input {
  border: none;
  border-bottom: 1px solid white;
  background: none !important;
}
.styles_input__15qZv.styles_has_icon__1bzrG input {
  padding: 5px 5px 5px 32px;
}
.styles_textarea__2FRB3 textarea {
  border-radius: 2px;
  background: #333333;
  box-shadow: #474747 0 0 0 1px;
  color: white;
  border: none;
  width: 100%;
  outline: none;
  padding: 10px;
  font-size: 16px;
}
.styles_select__2pFAe {
  position: relative;
}
.styles_select__2pFAe select {
  outline: none !important;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  padding: 5px;
  -webkit-transition: border 0.25s;
  transition: border 0.25s;
  font-size: 16px;
  background: #333333;
  border: #474747 1px solid;
  border-radius: 2px;
  -webkit-appearance: none;
}
.styles_select__2pFAe option {
  height: 40px;
}
.styles_select__2pFAe i {
  position: absolute;
  bottom: 9px;
  left: 7px;
  font-size: 18px;
}
.styles_select__2pFAe img {
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  bottom: 9px;
  left: 7px;
}
.styles_select__2pFAe.styles_has_icon__4H3vk select {
  padding: 5px 5px 5px 32px;
}

.styles_right__2ZUcW {
  position: absolute;
  bottom: 7px;
  right: 20px;
  pointer-events: none;
  touch-action: none;
}
.styles_loader__uhZgx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  z-index: 1;
}
.styles_container__19kQ4 {
  max-width: 770px;
}

.styles_slogan__1IVzW {
  letter-spacing: 3px;
  font-size: 63px;
  font-weight: 400;
  text-transform: uppercase;
}

.styles_subtitle__2VPsQ {
  text-transform: uppercase;
}

.styles_rounded__3AKa4 {
  text-transform: uppercase;
  margin: 0 40px;
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 20px;
  border: 2px solid #679ed2;
  background-color: #000;
  border-radius: 2px !important;
  font-family: "Roboto";
  padding-left: 30px;
  padding-right: 30px;
  color: white !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.styles_buttons__3gIaf {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.styles_buttons__3gIaf > * {
  margin: 0 10px;
}

.styles_homeWrapper__k6c8Y p {
  font-size: 14px;
}
.styles_homeWrapper__k6c8Y .styles_walletBtn__2lLge {
  background: transparent;
  border: 0;
  color: #fff;
  font-weight: 600;
  padding: 0;
  position: relative;
  width: 130px;
  text-align: left;
  cursor: pointer;
  outline: 0 !important;
}
.styles_homeWrapper__k6c8Y .styles_walletBtn__2lLge i {
  font-size: 20px;
  position: absolute;
  top: 3px;
  right: 0;
  margin-left: 20px;
}
.styles_homeWrapper__k6c8Y .styles_cardBody__3gS_J {
  padding: 10px;
}
.styles_homeWrapper__k6c8Y .styles_marginBottom__3jzfl {
  margin-bottom: 30px;
}
.styles_homeWrapper__k6c8Y .styles_card__4qP8t {
  padding: 30px;
  max-width: 525px;
  margin: 0 auto;
}

.styles_cardContent__2Nydk {
  display: flex;
  justify-content: start;
  align-items: center;
}
.styles_cardContent__2Nydk .styles_cardIcon__2J5sW {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: center;
}
.styles_cardContent__2Nydk .styles_homecontent__3onaG {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 30%);
  max-width: calc(100% - 30%);
}
.styles_cardContent__2Nydk .styles_text__29PwJ {
  min-height: 140px;
}

.styles_desktopView__36x6i {
  display: none;
}
@media (min-width: 767.98px) {
  .styles_desktopView__36x6i {
    display: block;
  }
}

.styles_mobileView__1JVPN {
  display: none;
}
@media (max-width: 767.98px) {
  .styles_mobileView__1JVPN {
    display: block;
  }
}

.styles_mobileBtn__2Q2da {
  width: 100%;
  height: 60px;
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  border: 0;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.styles_container__19kQ4 {
  max-width: 770px;
}

.styles_slogan__1IVzW {
  letter-spacing: 3px;
  font-size: 63px;
  font-weight: 400;
  text-transform: uppercase;
}

.styles_subtitle__2VPsQ {
  text-transform: uppercase;
}

.styles_rounded__3AKa4 {
  text-transform: uppercase;
  margin: 0 40px;
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 20px;
  border: 2px solid #679ed2;
  background-color: #000;
  border-radius: 2px !important;
  font-family: "Roboto";
  padding-left: 30px;
  padding-right: 30px;
  color: white !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.styles_banner__cdBkC {
  padding: 70px 0 120px;
}
@media (max-width: 767.98px) {
  .styles_banner__cdBkC {
    padding: 51px 0 90px;
  }
}
.styles_banner__cdBkC .styles_homeTitle__11SJf {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .styles_banner__cdBkC .styles_homeTitle__11SJf {
    font-size: 36px;
  }
}
.styles_banner__cdBkC h3 {
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 767.98px) {
  .styles_banner__cdBkC h3 {
    font-size: 16px;
  }
}

.styles_buttons__3gIaf {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.styles_buttons__3gIaf > * {
  margin: 0 10px;
}

.styles_homeWrapper__k6c8Y p {
  font-size: 14px;
}
.styles_homeWrapper__k6c8Y .styles_walletBtn__2lLge {
  background: transparent;
  border: 0;
  color: #fff;
  font-weight: 600;
  padding: 0;
  position: relative;
  width: 130px;
  text-align: left;
  cursor: pointer;
  outline: 0 !important;
}
.styles_homeWrapper__k6c8Y .styles_walletBtn__2lLge i {
  font-size: 20px;
  position: absolute;
  top: 3px;
  right: 0;
  margin-left: 20px;
}
.styles_homeWrapper__k6c8Y .styles_cardBody__3gS_J {
  padding: 10px;
}
.styles_homeWrapper__k6c8Y .styles_marginBottom__3jzfl {
  margin-bottom: 30px;
}
.styles_homeWrapper__k6c8Y .styles_card__4qP8t {
  padding: 30px;
  max-width: 525px;
  margin: 0 auto;
}

.styles_cardContent__2Nydk {
  display: flex;
  justify-content: start;
  align-items: center;
}
.styles_cardContent__2Nydk .styles_cardIcon__2J5sW {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: center;
}
.styles_cardContent__2Nydk .styles_homecontent__3onaG {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 30%);
  max-width: calc(100% - 30%);
}
.styles_cardContent__2Nydk .styles_text__29PwJ {
  min-height: 140px;
}

.styles_desktopView__36x6i {
  display: none;
}
@media (min-width: 767.98px) {
  .styles_desktopView__36x6i {
    display: block;
  }
}

.styles_mobileView__1JVPN {
  display: none;
}
@media (max-width: 767.98px) {
  .styles_mobileView__1JVPN {
    display: block;
  }
}

.styles_mobileBtn__2Q2da {
  width: 100%;
  height: 60px;
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  border: 0;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 767.98px) {
  .styles_card__3EuqB {
    padding-bottom: 24px;
  }
}

.styles_activities__3Yi-y {
  padding: 14px 17px 15px 18px;
}
.styles_activities__3Yi-y:not(:first-child) {
  border-top: 1px solid rgba(43, 57, 84, 0.1);
}
@media (max-width: 767.98px) {
  .styles_activities__3Yi-y {
    padding: 20px 0 18px;
  }
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_status__1gtyv,
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_time__2wD8B,
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_ftm__2JWDs {
  margin: 0;
  color: #2b3954;
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_status__1gtyv {
  width: 210px;
  font-size: 16px;
  font-weight: 500;
  flex: 0 0 210px;
  max-width: 210px;
}
@media (max-width: 767.98px) {
  .styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_status__1gtyv {
    display: none;
  }
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_status__1gtyv svg {
  margin-right: 10px;
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_timeFtmWrapper__1kzdB {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 210px);
  max-width: calc(100% - 210px);
}
@media (max-width: 767.98px) {
  .styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_timeFtmWrapper__1kzdB {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_time__2wD8B {
  font-size: 16px;
  font-weight: 500;
  color: rgba(43, 57, 84, 0.8);
}
@media (max-width: 767.98px) {
  .styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_time__2wD8B {
    font-size: 12px;
  }
}
.styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_ftm__2JWDs {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
}
@media (max-width: 767.98px) {
  .styles_activities__3Yi-y .styles_activitiesRow__zOYHf .styles_ftm__2JWDs {
    font-size: 14px;
  }
}

.styles_subView__237id {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}
@media (min-width: 767.98px) {
  .styles_subView__237id {
    padding-left: 40px;
  }
}
@media (max-width: 767.98px) {
  .styles_subView__237id {
    margin-bottom: 16px;
  }
}
.styles_subView__237id:first-child {
  margin-top: 37px;
}
@media (max-width: 767.98px) {
  .styles_subView__237id:first-child {
    margin-top: 20px;
  }
}
.styles_subView__237id:last-child {
  margin-bottom: 13px;
}
@media (max-width: 767.98px) {
  .styles_subView__237id:last-child {
    margin-bottom: 2px;
  }
}
.styles_subView__237id .styles_subViewTitle__TZSFn,
.styles_subView__237id .styles_subViewValue__1cIGQ {
  margin: 0;
  color: #2b3954;
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .styles_subView__237id .styles_subViewTitle__TZSFn,
.styles_subView__237id .styles_subViewValue__1cIGQ {
    font-size: 12px;
    line-height: 1.33;
  }
}
.styles_subView__237id .styles_subViewTitle__TZSFn {
  font-weight: 500;
  flex: 0 0 112px;
  max-width: 112px;
}
@media (max-width: 767.98px) {
  .styles_subView__237id .styles_subViewTitle__TZSFn {
    opacity: 0.7;
    margin-bottom: 4px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.styles_subView__237id .styles_subViewValue__1cIGQ {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 112px);
  max-width: calc(100% - 112px);
  font-weight: 600;
  display: flex;
  word-break: break-all;
  align-items: baseline;
}
@media (min-width: 767.98px) {
  .styles_subView__237id .styles_subViewValue__1cIGQ {
    padding-left: 8px;
  }
}
@media (max-width: 767.98px) {
  .styles_subView__237id .styles_subViewValue__1cIGQ {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.styles_subView__237id .styles_subViewValue__1cIGQ button {
  cursor: pointer;
  outline: none !important;
  background: transparent;
  border: 0;
  padding: 0;
  margin-left: 20px;
  color: rgba(43, 57, 84, 0.5);
}
.styles_subView__237id .styles_subViewValue__1cIGQ button:hover {
  color: rgba(43, 57, 84, 0.7);
}
.styles_subView__237id .styles_subViewValue__1cIGQ button svg {
  width: 18px;
  height: 20px;
}
.styles_wrap__2r3Os {
  margin: 60px auto;
}

.styles_row__1xuZf {
  padding: 15px 0;
  background: #171717;
}

.styles_transactions__17e-D {
  display: flex;
}

.styles_createWalletModal__2sSMf {
  max-width: 570px;
}
.styles_createWalletModal__2sSMf > div {
  border-radius: 2px !important;
}

.styles_refreshBtnWrapper__3ckmT {
  position: absolute;
  top: 20px;
  right: 30px;
}
.styles_refreshBtnWrapper__3ckmT .styles_refreshBtn__2IKjd {
  line-height: 1;
}

.styles_ftmNumber__3xa8- {
  font-size: 36px;
  font-weight: 600;
}

.styles_ftmText__1Hwsc {
  font-size: 24px;
  font-weight: 600;
}

.styles_infoValue__aB1lZ {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
}
.styles_header__1BqV- {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  z-index: 999;
  top: 0;
  left: 0;
}
@media (max-width: 767.98px) {
  .styles_header__1BqV- {
    padding: 25px;
  }
}
@media (max-width: 359.98px) {
  .styles_header__1BqV- {
    padding: 25px 15px;
  }
}
@media (max-width: 767.98px) {
  .styles_header__1BqV- .styles_logo__1NLbc {
    height: 31px;
  }
}

.styles_sidebarWrapper__4VIe1 {
  flex: 0 0 325px;
}
@media (min-width: 360.98px) {
  .styles_sidebarWrapper__4VIe1 {
    max-width: 325px;
  }
}
@media (max-width: 1200.98px) {
  .styles_sidebarWrapper__4VIe1 {
    position: fixed;
    top: 0;
    right: -325px;
    width: 100%;
    height: 100vh;
    z-index: 999;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
  }
  .styles_sidebarWrapper__4VIe1.styles_active__Liv7G {
    right: 0;
    opacity: 1;
  }
}
@media (max-width: 360.98px) {
  .styles_sidebarWrapper__4VIe1 {
    right: -100vw;
  }
}

.styles_root__1GApU {
  position: relative;
  background: #131313;
  height: 100%;
}
@media (min-width: 1200.98px) {
  .styles_root__1GApU {
    padding-top: 40px;
    border-top-right-radius: 2px;
  }
}
@media (max-width: 1200.98px) {
  .styles_root__1GApU {
    border-top-left-radius: 2px;
  }
}
@media (max-width: 1200.98px) {
  .styles_root__1GApU .styles_head__30-Hx {
    position: absolute;
    width: 100%;
    height: 84px;
    left: 0;
    top: 0;
    border-top-left-radius: 2px;
    background: #131313;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 25px;
  }
}

.styles_logoWrapper__33ZrE {
  text-align: center;
}
@media (min-width: 767.98px) and (max-width: 1200.98px) {
  .styles_logoWrapper__33ZrE {
    display: none;
  }
}
@media (min-width: 1200.98px) {
  .styles_logoWrapper__33ZrE {
    margin-bottom: 97px;
  }
}
@media (max-width: 767.98px) {
  .styles_logoWrapper__33ZrE img {
    height: 31px;
  }
}

.styles_close__2YAXH {
  line-height: 1;
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  position: absolute;
  right: 37px;
  top: 27px;
}
@media (max-width: 767.98px) {
  .styles_close__2YAXH {
    top: 16px;
    right: 25px;
  }
}

@media (max-width: 1200.98px) {
  .styles_menusWrapper__1hakx {
    padding-top: 178px;
    padding-top: 178px;
    padding-bottom: 50px;
    height: 100vh;
    overflow: auto;
  }
  .styles_menusWrapper__1hakx::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
@media (max-width: 767.98px) {
  .styles_menusWrapper__1hakx {
    padding-top: 162px;
  }
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m {
  padding: 0 15px;
  display: table;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m li {
  list-style: none;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m li:not(:last-child) {
  margin-bottom: 80px;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m li:not(.styles_active__Liv7G) a {
  opacity: 0.5;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m li:not(.styles_active__Liv7G) a:hover {
  opacity: 1;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff !important;
  font-size: 24px;
  font-weight: 600;
}
.styles_menusWrapper__1hakx .styles_menus__3eR-m a img {
  margin-right: 20px;
}
.styles_qrHashAddress__3PS8i {
  word-break: break-all;
  padding: 0 14px;
}

.styles_root__8N_GU {
  background: #ecf0f8;
  position: relative;
}
@media (min-width: 767.98px) {
  .styles_root__8N_GU::before {
    content: "";
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 269px;
    z-index: 1;
  }
}
@media (max-width: 1200.98px) {
  .styles_root__8N_GU.styles_withoutCard__7LP-Y::before {
    height: 338px;
  }
}

.styles_wrapper__3AmnA {
  min-height: 100vh;
  position: relative;
  position: relative;
  z-index: 2;
}
@media (min-width: 1200.98px) {
  .styles_wrapper__3AmnA {
    display: flex;
    flex-wrap: wrap;
  }
}

.styles_sidebarWrapper__2rZnq {
  flex: 0 0 325px;
  max-width: 325px;
}
@media (max-width: 1200.98px) {
  .styles_sidebarWrapper__2rZnq {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
}

.styles_contentWrapper__18d64 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 325px);
  max-width: calc(100% - 325px);
  padding: 70px 0 33px;
}
@media (max-width: 1200.98px) {
  .styles_contentWrapper__18d64 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 9px;
  }
}
@media (max-width: 767.98px) {
  .styles_contentWrapper__18d64 {
    padding-top: 0px;
  }
}
.styles_contentWrapper__18d64 .styles_main__2RpxY {
  padding: 0 55px 0 30px;
}
@media (max-width: 1200.98px) {
  .styles_contentWrapper__18d64 .styles_main__2RpxY {
    padding: 0 30px;
  }
}
@media (max-width: 767.98px) {
  .styles_contentWrapper__18d64 .styles_main__2RpxY {
    padding: 0 25px;
  }
}
@media (max-width: 359.98px) {
  .styles_contentWrapper__18d64 .styles_main__2RpxY {
    padding: 0 15px;
  }
}
.styles_contentWrapper__18d64 .styles_contentHeader__13xZb {
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .styles_contentWrapper__18d64 .styles_contentHeader__13xZb {
    margin-bottom: 0px;
    background: #fff;
    margin-left: -25px;
    margin-right: -25px;
    padding: 14px 25px 20px;
  }
}
@media (max-width: 359.98px) {
  .styles_contentWrapper__18d64 .styles_contentHeader__13xZb {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1200.98px) {
  .styles_contentWrapper__18d64 .styles_contentHeader__13xZb:not(.styles_withoutCard__7LP-Y) {
    margin-bottom: 20px;
  }
}

.styles_sync__3EVK3 {
  color: #2b3954;
  font-size: 16px;
  margin: 0;
}
.styles_sync__3EVK3 svg {
  color: #21d3b1;
  margin-right: 10px;
}

.styles_label__eq0DE {
  font-size: 18px;
  font-weight: 600;
  color: rgba(43, 57, 84, 0.5);
  margin-bottom: 21px;
}
@media (max-width: 767.98px) {
  .styles_label__eq0DE {
    margin-bottom: 16px;
  }
}

.styles_hashWrapper__3kWsI {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .styles_hashWrapper__3kWsI > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.styles_hashWrapper__3kWsI .styles_hash__hAFoU {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  word-break: break-all;
  color: #2b3954;
}
@media (max-width: 1200.98px) {
  .styles_hashWrapper__3kWsI .styles_hash__hAFoU {
    max-width: 470px;
  }
}
@media (max-width: 767.98px) {
  .styles_hashWrapper__3kWsI .styles_hash__hAFoU {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
.styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf {
  display: flex;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button {
  outline: none !important;
  cursor: pointer;
  color: rgba(43, 57, 84, 0.7);
  background: transparent;
  border: 0;
  padding: 0;
  line-height: 1;
}
.styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button:hover {
  color: rgba(43, 57, 84, 0.9);
}
@media (min-width: 767.98px) {
  .styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button {
    margin-left: 30px;
  }
}
@media (max-width: 767.98px) {
  .styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button {
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button svg {
    height: 20px;
    width: 20px;
  }
  .styles_hashWrapper__3kWsI .styles_hashHandlers__1GRmf button svg[class*=CopyIcon] {
    width: 18px;
  }
}
.styles_qrWrapper__8_FOG {
  position: relative;
  display: inline-block;
  border: 6px solid #fff;
}
@media (max-width: 359.98px) {
  .styles_qrWrapper__8_FOG canvas {
    width: 214px !important;
    height: 214px !important;
  }
}
.styles_iconGap__dScis {
  margin-bottom: 40px;
}

.styles_copyBtn__15G9P {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

.styles_card__2AT6R {
  text-align: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.styles_hash__3TEFy {
  display: flex;
  flex-wrap: wrap;
}
.styles_hash__3TEFy a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 18px);
  max-width: calc(100% - 18px);
}
.styles_hash__3TEFy .styles_copyBtn__15G9P {
  flex: 0 0 18px;
  max-width: 18px;
}
.styles_hash__3TEFy .styles_copyBtn__15G9P svg {
  width: 18px;
  height: 20px;
  color: rgba(43, 57, 84, 0.5);
}

@media (max-width: 767.98px) {
  .styles_headWrapper__rd5-4 {
    background: #fff;
    margin: 0 -25px 20px;
    padding: 0px 25px 20px;
  }
}
@media (max-width: 359.98px) {
  .styles_headWrapper__rd5-4 {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.styles_qrWrapper__3kqfS {
  margin-bottom: 40px;
}

.styles_cardTitle__2Pzuj {
  margin-bottom: 70px;
}
@media (max-width: 767.98px) {
  .styles_cardTitle__2Pzuj {
    margin-bottom: 40px;
  }
}

.styles_btnWrapper__23osA {
  display: flex;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.styles_optionBtn__g-bxM {
  margin: 0 7px;
  min-width: 80px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_optionBtn__g-bxM svg {
  margin-bottom: 12px;
}

.styles_amountInput__3myy_ {
  max-width: 245px;
  margin: 10px auto 31px;
  display: flex;
  background-color: #f8f9fa;
  border-radius: 2px;
}
.styles_amountInput__3myy_ input {
  background-color: #f8f9fa;
  border: 0;
  font-size: 18px;
  height: 40px;
}
.styles_amountInput__3myy_ input::-webkit-input-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_amountInput__3myy_ input:-moz-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_amountInput__3myy_ input::-moz-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_amountInput__3myy_ input:-ms-input-placeholder {
  color: rgba(43, 57, 84, 0.3);
  font-weight: 400;
}
.styles_amountInput__3myy_ button {
  outline: none !important;
  font-size: 18px;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  border: solid 2px rgba(43, 57, 84, 0.2);
  color: #222222;
}

@media (max-width: 767.98px) {
  .styles_headWrapper__3E6W0 {
    background: #fff;
    margin: 0 -25px 20px;
    padding: 0px 25px 20px;
  }
}
@media (max-width: 359.98px) {
  .styles_headWrapper__3E6W0 {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.styles_validatorBtn__1CqxG {
  width: 300px;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 767.98px) {
  .styles_validatorBtn__1CqxG {
    font-size: 18px !important;
    width: 100%;
  }
}
@media (max-width: 359.98px) {
  .styles_validatorBtn__1CqxG {
    font-size: 16px !important;
  }
}
.styles_validatorBtn__1CqxG i {
  position: absolute;
  top: 50%;
  right: 21px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.styles_iconGap__3K_Zm {
  margin-bottom: 40px;
}

.styles_copyBtn__2DgS4 {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

.styles_card__3xXX2 {
  text-align: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .styles_card__3xXX2 svg {
    width: 79px;
    height: 79px;
  }
}

.styles_hash__228WT {
  display: flex;
  flex-wrap: wrap;
}
.styles_hash__228WT a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 18px);
  max-width: calc(100% - 18px);
}
.styles_hash__228WT .styles_copyBtn__2DgS4 {
  flex: 0 0 18px;
  max-width: 18px;
}
.styles_hash__228WT .styles_copyBtn__2DgS4 svg {
  width: 18px;
  height: 20px;
  color: rgba(43, 57, 84, 0.5);
}

.styles_availableWrapper__3TrNy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.styles_availableWrapper__3TrNy > h3,
.styles_availableWrapper__3TrNy button {
  margin: 0 14px;
}
@media (max-width: 767.98px) {
  .styles_availableWrapper__3TrNy > h3,
.styles_availableWrapper__3TrNy button {
    ont-size: 16px;
  }
}
.styles_availableWrapper__3TrNy button {
  box-shadow: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  border: 0;
  background: transparent;
  color: #131313;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.styles_availableWrapper__3TrNy button:hover {
  text-decoration: underline;
  color: black;
}
.styles_availableWrapper__3TrNy button img {
  margin-left: 10px;
}

.styles_transCard__3o3is {
  text-align: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.styles_stakeButtonsWrap__3axWL {
  max-width: 628px;
  margin: 30px auto 0;
}
.styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce {
  margin-right: -40px;
  margin-left: -40px;
}
@media (max-width: 1200.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce {
    margin-right: -35px;
    margin-left: -35px;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce .styles_col__1h44Y {
  padding-right: 40px;
  padding-left: 40px;
}
@media (max-width: 1200.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce .styles_col__1h44Y {
    padding-right: 35px;
    padding-left: 35px;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce .styles_col__1h44Y {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 359.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce .styles_col__1h44Y {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_row__1N6Ce .styles_col__1h44Y button {
    font-size: 16px;
    min-width: 122px;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_rewardBtn__1uq-i {
    order: 1;
    margin-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_unstakeBtn__2jeHv {
    order: 2;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeButtonsWrap__3axWL .styles_stakeBtn__EW1pY {
    order: 3;
  }
}

@media (max-width: 767.98px) {
  .styles_stakeCard__v9Fhc h2 {
    font-size: 18px;
  }
}

.styles_stakeBtnsWrap__pdXAU {
  margin: 0 auto;
  max-width: 480px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 359.98px) {
  .styles_stakeBtnsWrap__pdXAU {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .styles_stakeBtnsWrap__pdXAU button {
    min-width: 122px;
  }
}
@media (max-width: 359.98px) {
  .styles_stakeBtnsWrap__pdXAU button {
    margin-bottom: 10px;
  }
}

.styles_card__3xXX2 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
@media (max-width: 767.98px) {
  .styles_card__3xXX2 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media (min-width: 767.98px) {
  .styles_inputsWrapper__2IwCl {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.styles_title__2khVz {
  font-weight: 800;
}

.styles_btnWrapper__2nGYc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.styles_btn__MLpQf {
  width: 200px;
}
.styles_btn__MLpQf.styles_send__15ea8 {
  margin-bottom: 10px;
  font-size: 21px;
}
.styles_btn__MLpQf.styles_clear__3mTq- {
  font-size: 14px;
  font-weight: 500;
}

@media (min-width: 767.98px) {
  .styles_passwordModal__3pde4 {
    max-width: 870px;
  }
}
.styles_passwordModal__3pde4 > div {
  border-radius: 2px;
  border: 0;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
}
@media (min-width: 767.98px) {
  .styles_passwordModal__3pde4 > div {
    min-height: 301px;
  }
}
.styles_passwordModal__3pde4 .styles_body__TO5qU {
  padding: 20px 40px 40px;
}
.styles_passwordModal__3pde4 .styles_sendBtn__grFIj {
  margin-top: 35px;
  width: 200px;
}
.styles_passwordModal__3pde4 label {
  font-size: 18px !important;
}
.styles_passwordModal__3pde4 p {
  margin-top: 20px !important;
}
.styles_passwordModal__3pde4 ::-webkit-input-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__3pde4 ::-moz-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__3pde4 ::-ms-input-placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.styles_passwordModal__3pde4 ::placeholder {
  color: rgba(43, 57, 84, 0.3) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.styles_iconGap__3K_Zm {
  margin-bottom: 40px;
}

.styles_copyBtn__2DgS4 {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

.styles_transCard__3o3is {
  text-align: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.styles_hash__228WT {
  display: flex;
  flex-wrap: wrap;
}
.styles_hash__228WT a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 24px;
  font-weight: 600;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 18px);
  max-width: calc(100% - 18px);
}
.styles_hash__228WT .styles_copyBtn__2DgS4 {
  flex: 0 0 18px;
  max-width: 18px;
}
.styles_hash__228WT .styles_copyBtn__2DgS4 svg {
  width: 18px;
  height: 20px;
  color: rgba(43, 57, 84, 0.5);
}
.styles_title__1Ne_i {
  margin-bottom: 6px;
}
@media (max-width: 767.98px) {
  .styles_title__1Ne_i {
    font-size: 18px;
    margin-bottom: 4px;
  }
}

.styles_dec__3qNta {
  margin-bottom: 31px;
}
@media (max-width: 767.98px) {
  .styles_dec__3qNta {
    margin-bottom: 29px;
  }
}

@media (max-width: 767.98px) {
  .styles_card__3I5Rc {
    padding-bottom: 10px !important;
  }
}

@media (max-width: 767.98px) {
  .styles_table__YDxOR {
    margin-bottom: 0px;
  }
}
@media (max-width: 767.98px) {
  .styles_table__YDxOR .styles_tableHead__2oCRW {
    display: none;
  }
}
.styles_table__YDxOR th,
.styles_table__YDxOR td {
  padding-left: 10px;
  padding-right: 10px;
  border-color: rgba(43, 57, 84, 0.1);
}
.styles_table__YDxOR th {
  border: 0 !important;
  font-size: 12px;
  font-weight: 600;
  color: rgba(43, 57, 84, 0.5);
}
.styles_table__YDxOR th svg {
  margin-left: 10px;
}
.styles_table__YDxOR th.styles_up__QF3bw polygon:first-child {
  color: #131313;
}
.styles_table__YDxOR th.styles_down__MHn1e polygon:last-child {
  color: #131313;
}

.styles_validatingPowerLable__22-Q9 {
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 600;
  color: rgba(43, 57, 84, 0.5);
}

.styles_txDetails__au4a0 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.31;
  color: rgba(43, 57, 84, 0.8);
}
@media (max-width: 767.98px) {
  .styles_txDetails__au4a0 {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }
}
.styles_txDetails__au4a0 b {
  font-weight: 600;
  color: #2b3954;
}
.styles_txDetails__au4a0.styles_index__15gN_ {
  line-height: 1.5;
  color: rgba(43, 57, 84, 0.9);
}
@media (max-width: 767.98px) {
  .styles_txDetails__au4a0.styles_index__15gN_ {
    font-weight: 600;
    line-height: 1.33;
    color: #2b3954;
  }
}

.styles_indexBox__l--dW {
  width: 31px;
}
@media (max-width: 767.98px) {
  .styles_indexBox__l--dW {
    width: 14px;
  }
}

.styles_contentRow__1wSm4 {
  cursor: pointer;
}
.styles_contentRow__1wSm4:first-child td {
  border-top: 0;
}
.styles_contentRow__1wSm4 td {
  vertical-align: middle;
  border-bottom: 0;
}
@media (min-width: 767.98px) {
  .styles_contentRow__1wSm4 td {
    height: 59px;
  }
}
@media (max-width: 767.98px) {
  .styles_contentRow__1wSm4 td {
    padding: 10px 0;
    vertical-align: top;
  }
}

.styles_subViewRow__1BxSc td {
  border-top: 0;
}
@media (max-width: 767.98px) {
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr {
    display: flex;
    flex-direction: column;
  }
}
.styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td {
  vertical-align: middle;
}
@media (min-width: 767.98px) {
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td {
    height: 59px;
  }
}
@media (max-width: 767.98px) {
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td .styles_txDetails__au4a0.styles_label__1JjQw {
    font-weight: 600;
    font-size: 10px;
    color: rgba(43, 57, 84, 0.5);
    margin-bottom: 4px;
  }
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td .styles_txDetails__au4a0.styles_label__1JjQw span {
    display: none;
  }
  .styles_subViewRow__1BxSc .styles_subViewTable__1IoV1 tr td .styles_txDetails__au4a0.styles_value__EtFyk {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 12px;
  }
}

@media (min-width: 767.98px) {
  .styles_subViewContainer__wOWje {
    padding: 12px 50px 40px;
  }
}
@media (max-width: 767.98px) {
  .styles_subViewContainer__wOWje {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .styles_selectBtn__2KgvD {
    width: 100%;
    font-size: 16px !important;
  }
}
.styles_successModal__mj4Hf {
  text-align: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  max-width: 470px;
  width: calc(100% - 50px);
  margin: 0 auto;
  padding: 40px 40px 30px;
  z-index: 999999;
  background: #fff;
  border-radius: 2px;
  margin-top: 240px;
}
@media (max-width: 767.98px) {
  .styles_successModal__mj4Hf {
    padding: 25px;
    margin-top: 150px;
  }
}
.styles_successModal__mj4Hf svg {
  width: 60px;
  height: 60px;
}
.styles_successModal__mj4Hf p {
  font-weight: 500;
}
.styles_successModal__mj4Hf button {
  width: 220px;
  color: #000 !important;
  border-color: #222222 !important;
  font-size: 16px;
}
.styles_successModal__mj4Hf button:hover, .styles_successModal__mj4Hf button:active, .styles_successModal__mj4Hf button:focus {
  border-color: #090909;
  color: #000;
  background: transparent !important;
}
.styles_optionsWrapper__1ro8V {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 51px;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 200px;
  max-width: 200px;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA .styles_option__1U7q1 {
  width: 100%;
  border-radius: 2px;
  padding: 20px 12px;
  text-align: center;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA .styles_option__1U7q1 h4 {
  margin-bottom: 2px;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA .styles_option__1U7q1:not(.styles_active__YnDA8) {
  background-color: #f8f9fa;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA .styles_option__1U7q1.styles_active__YnDA8 {
  border: solid 3px #222222;
}
.styles_optionsWrapper__1ro8V .styles_optionCol__e2TGA .styles_option__1U7q1 svg {
  margin-bottom: 17px;
}

.styles_inputWrapper__c9HTa {
  position: relative;
}
.styles_inputWrapper__c9HTa .styles_errorText__2eUhW {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #d0021b;
}

.styles_inputLabel__18J-Z {
  margin-bottom: 10px;
}

.styles_input__3U5_V {
  font-size: 16px;
  font-weight: 500;
  border: 0;
  color: #2b3954;
  border-radius: 2px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #f8f9fa !important;
  width: 100%;
  padding: 15px 16px;
}
.styles_input__3U5_V.styles_isError__3rpGk {
  border: solid 1px #d0021b;
}
.styles_input__3U5_V.styles_textarea__2fMAd {
  width: 100% !important;
  min-height: 130px;
  height: 130px;
  max-height: 200px;
  margin-bottom: 80px;
}

.styles_btn__2sFpZ {
  width: 240px;
}
.styles_modal__2NiCn {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  max-width: 0;
  background: red;
  z-index: 11;
  background: #171717;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  color: white;
  overflow: hidden;
  will-change: max-width;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  z-index: 5;
}
.styles_modal__2NiCn.styles_active__2ekri {
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.7) -30px 0 60px, inset rgba(255, 255, 255, 0.1) 1px 0;
}

.styles_header__J3W9L {
  flex: 0 0 80px;
  border-bottom: 1px solid #222222;
  height: 100vh;
  width: 100vw;
  max-width: 500px;
}

.styles_content__3MKdt {
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  overflow: auto;
  flex: 1 1;
}

.styles_shade__1_SNL {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.styles_shade__1_SNL.styles_active__2ekri {
  opacity: 1;
}

.styles_close__1VzYF {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated.bounceIn {
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
}
.animated.bounceOut {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.animated.bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
}
.animated.bounceOut {
  -webkit-animation-name: bounceOut;
          animation-name: bounceOut;
}
.animated.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
}
.animated.fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
          animation-name: bounceInDown;
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.rrt-confirm-holder .shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(50, 58, 68, 0.8);
}
.rrt-confirm-holder .rrt-confirm {
  width: 320px;
  background-color: white;
  position: absolute;
  z-index: 9;
  top: 20%;
  left: 50%;
  margin-left: -160px;
  box-shadow: 3px 3px 20px #333;
  border-radius: 4px;
  overflow: hidden;
}
.rrt-confirm-holder .rrt-confirm .rrt-message {
  width: 100%;
  padding: 5%;
  min-height: 50px;
  font-size: 1em;
  background-color: white;
  text-align: center;
  font-family: "open-sanscondensed-light", sans-serif;
  clear: both;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
  display: flex;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  flex-grow: 1;
  height: 50px;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  float: left;
  border-top: 1px solid #f0f0f0;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
  background-color: #f5f5f5;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
  background-color: #60bb71;
  color: white;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
  background-color: #db6a64;
  color: white;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
  outline: none;
}

body.toastr-confirm-active {
  overflow: hidden;
}

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box;
}
.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px;
}
.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0;
}
.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0;
}
.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0;
}
.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0;
}
.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px;
}
@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px;
  }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px;
  }
}
.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 0.94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
.redux-toastr .toastr:hover:not(.rrt-message) {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.redux-toastr .toastr .toastr-status {
  width: 100%;
  height: 5px;
}
.redux-toastr .toastr .toastr-status.success {
  background-color: #60bb71;
}
.redux-toastr .toastr .toastr-status.warning {
  background-color: #f7a336;
}
.redux-toastr .toastr .toastr-status.info {
  background-color: #58abc3;
}
.redux-toastr .toastr .toastr-status.error {
  background-color: #db6a64;
}
.redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
  float: left;
  text-align: center;
  overflow: hidden;
}
.redux-toastr .toastr .rrt-left-container {
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  bottom: 0;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  margin-top: -35px;
  left: 5px;
  line-height: 60px;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: white;
  vertical-align: middle;
  margin-top: 5px;
}
.redux-toastr .toastr .rrt-middle-container {
  width: 65%;
  margin-left: 80px;
  position: relative;
  float: left;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  font-size: 1em;
  text-align: left;
  padding: 10px 5px;
}
.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
}
.redux-toastr .toastr .rrt-right-container {
  width: 10%;
}
.redux-toastr .toastr .close-toastr {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  font-size: 22px;
  border: none;
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
  color: #000;
}
.redux-toastr .toastr .close-toastr:hover {
  opacity: 1;
}
.redux-toastr .toastr .close-toastr:focus {
  outline: none;
}
.redux-toastr .toastr .close-toastr span {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
  color: white;
}
.redux-toastr .toastr.rrt-info {
  background-color: #58abc3;
}
.redux-toastr .toastr.rrt-info .rrt-progressbar {
  background-color: #378298;
}
.redux-toastr .toastr.rrt-success {
  background-color: #60bb71;
}
.redux-toastr .toastr.rrt-success .rrt-progressbar {
  background-color: #3e914d;
}
.redux-toastr .toastr.rrt-warning {
  background-color: #f7a336;
}
.redux-toastr .toastr.rrt-warning .rrt-progressbar {
  background-color: #d87e09;
}
.redux-toastr .toastr.rrt-error {
  background-color: #db6a64;
}
.redux-toastr .toastr.rrt-error .rrt-progressbar {
  background-color: #c5352e;
}
.redux-toastr .toastr.rrt-light .rrt-progressbar {
  background-color: #ccc;
}
.redux-toastr .toastr.rrt-light .toastr-icon {
  fill: #333 !important;
}
.redux-toastr .toastr.rrt-message {
  opacity: 1;
  border: 1px solid #dbdbdb;
}
.redux-toastr .toastr.rrt-message .rrt-title {
  width: 90%;
  height: 50px;
  text-align: center;
  overflow: hidden;
  font-size: 1.2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 50px;
  padding: 0 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  padding: 15px;
  font-size: 1.1em;
  margin-bottom: 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text img {
  display: block;
  margin: 10px auto;
  max-width: 100%;
}
.redux-toastr .toastr.rrt-message .close-toastr {
  height: 50px;
}
.redux-toastr .toastr .rrt-progress-container {
  height: 5px;
  margin: 0 -20px -20px -60px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
  border-radius: 0 0 0 4px;
  height: 100%;
}
.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

body {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-underline {
  text-decoration: underline;
}
.text-primary {
  color: #131313 !important;
}
.text-dark-grey-blue {
  color: #2b3954 !important;
}
.text-grey {
  color: #556176 !important;
}
.text-danger {
  color: #ee3131 !important;
}
.text-topaz {
  color: #222222 !important;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
}
.text-usd {
  font-size: 24px;
  font-weight: 300;
  color: rgba(43, 57, 84, 0.5);
}

a {
  color: #131313;
}
a:hover {
  color: black;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-extra-bold {
  font-weight: 800;
}

ExtraBold h1 {
  font-size: 36px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  h2 {
    font-size: 18px;
  }
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-size: 12px;
  font-weight: 500;
}

.btn {
  border: 2px solid;
  border-radius: 0px;
  height: 50px;
  font-size: 18px;
  min-width: 156px;
  font-weight: 600;
  color: #fff;
  box-shadow: none !important;
  outline: none !important;
}
@media (max-width: 767.98px) {
  .btn {
    font-size: 16px;
  }
}
.btn.lg {
  font-size: 21px;
}
@media (max-width: 767.98px) {
  .btn.lg {
    font-size: 16px;
  }
}
.btn-icon {
  outline: none !important;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: 0;
}
.btn-secondary {
  color: #fff;
  background: #d5d5d5;
  border-color: #d5d5d5;
}
.btn-secondary:hover, .btn-secondary:active {
  background: #bcbcbc !important;
  border-color: #bcbcbc !important;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background: #d5d5d5 !important;
  border-color: #d5d5d5 !important;
}
.btn-primary {
  background-color: #131313;
  border-color: #131313;
}
.btn-primary:hover, .btn-primary:active {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-darkish-pink {
  color: #fff;
  background-color: #ec3297;
  border-color: #ec3297;
}
.btn-darkish-pink:hover, .btn-darkish-pink:active {
  background-color: #d7147e;
  border-color: #d7147e;
}
.btn-dark-periwinkle {
  background-color: #3072f8;
  border-color: #3072f8;
}
.btn-dark-periwinkle:hover, .btn-dark-periwinkle:active {
  color: #fff;
  background-color: #0854ed;
  border-color: #0854ed;
}
.btn-topaz {
  background-color: #222222;
  border-color: #222222;
}
.btn-topaz:hover, .btn-topaz:active {
  color: #fff;
  background-color: #090909;
  border-color: #090909;
}
.btn.outlined {
  background: transparent !important;
}
.btn.outlined.btn-primary {
  color: #131313;
}
.btn.outlined.btn-primary:hover, .btn.outlined.btn-primary:active {
  color: black;
}
.btn.outlined.btn-darkish-pink {
  color: #ec3297;
}
.btn.outlined.btn-darkish-pink:hover, .btn.outlined.btn-darkish-pink:active {
  color: #d7147e;
}
.btn.outlined.btn-dark-periwinkle {
  color: #3072f8;
}
.btn.outlined.btn-dark-periwinkle:hover, .btn.outlined.btn-dark-periwinkle:active {
  color: #0854ed;
}
.btn.outlined.btn-topaz {
  color: #222222;
}
.btn.outlined.btn-topaz:hover, .btn.outlined.btn-topaz:active {
  color: #090909;
}

@media (max-width: 1200.98px) {
  .row {
    margin-right: -11px;
    margin-left: -11px;
  }
}

@media (max-width: 1200.98px) {
  .col,
[class*=col-] {
    padding-right: 11px;
    padding-left: 11px;
  }
}

@media (max-width: 767.98px) {
  .no-mobile {
    display: none;
  }
}

.bg-dark-periwinkle {
  background: #3072f8 !important;
}
.bg-topaz {
  background: #222222 !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3.75rem !important;
}

@media (min-width: 767.98px) {
  .mb-md-6,
.my-md-6 {
    margin-bottom: 3.75rem !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 3.75rem !important;
  }
}
.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3.75rem !important;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-85 {
  opacity: 0.85;
}

.card {
  border: 0;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}
.card .card-label {
  font-size: 18px;
  font-weight: 600;
  color: rgba(43, 57, 84, 0.5);
}
@media (max-width: 767.98px) {
  .card .card-label {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spin {
  -webkit-animation: 1s refresh-spin infinite linear;
          animation: 1s refresh-spin infinite linear;
}
@-webkit-keyframes refresh-spin {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes refresh-spin {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.pointer {
  cursor: pointer;
}

.redux-toastr .toastr.rrt-success {
  background-color: #131313;
}
.redux-toastr .toastr.rrt-success .rrt-progressbar {
  background-color: white;
}
