$panel_height: 48px;

$color_primary: #00b1ff;
$color_gray: #232323;
$color_darkgray: #171717;
$color_lightgray: #222222;
$color_red: red;

$color_input_bg: #333333;
$modal_shadow: transparentize(black, 0.3) -30px 0 60px,
  inset transparentize(white, 0.9) 1px 0;

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

$color: (
  White: #fff,
  Black: #000,
  Primary-Main: #131313,
  dark-grey-blue: #2b3954,
  Grey-Main: #556176,
  Error: #ee3131,
  scarlet: #d0021b,
  darkish-pink: #ec3297,
  dark-periwinkle: #3072f8,
  topaz: #222222,
);

$mediaLarge: 1440.98px;
$mediaTablet: 1260.98px;
$largeDesktop: 1200.98px;
$desktop: 991.98px;
$tablet: 767.98px;
$phone: 575.98px;
$smallPhone: 359.98px;

$dashboardSidebarWidth: 325px;
